/* Ensure there is no border or outline for selected tabs */
.react-tabs__tab--selected {
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    background: none !important;
}

/* Remove outline when tab is focused */
.react-tabs__tab:focus {
    outline: none !important;
}

/* Default tab styling (unselected) */
.react-tabs__tab {
    border: none !important;
    box-shadow: none !important;
    background: none !important;
    outline: none !important;
}

/* Reset hover/focus states to avoid unwanted borders or outlines */
.react-tabs__tab:focus-visible,
.react-tabs__tab:hover {
    outline: none !important;
    box-shadow: none !important;
    background: none !important;
}