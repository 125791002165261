@tailwind base;
@tailwind components;
@tailwind utilities;


body {
  margin: 0;
  font-family: Arialsans-serif;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
}



code {
  /* font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace; */
}

/* Default styles (for mobile devices) */
#root {
  width: 100%;
  height: 100%;
  overflow: auto;
  overflow-x: hidden;
  background-color: #fff;
}

/* Styles for web resolutions (desktop and larger tablets) */
@media screen and (min-width: 768px) {
  body {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f0f0f0; /* Mimic mobile app view on larger screens */
    height: 100vh;
    overflow: hidden; /* Prevent scrolling outside the container */
  }

  #root {
    width: 375px; /* Fixed width of a typical mobile device */
    height: 812px; /* Fixed height of a typical mobile device, e.g., iPhone X */
    border: 1px solid #ccc; /* Optional: Mimic a mobile device border */
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Optional shadow for better aesthetics */
    background-color: #fff;
    overflow-y: auto; /* Allow scrolling inside the simulated mobile view */
  }
}